import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance, { errorHandler } from '../utils/axios';

const initialState = {
    step1: null,
    step2: null,
    step3: null,
    step4: null,
    step5: null
};

// export const getPostsAsync = createAsyncThunk('posts/getPostsAsync', async (values, { rejectWithValue }) => {
//     try {
//         const response = await axiosInstance.get('/posts');
//         return response.data;
//     } catch (e) {
//         return errorHandler(e, rejectWithValue);
//     }
// });

const form = createSlice({
    name: 'form',
    initialState,
    reducers: {
        resetState: () => initialState,
        setStepIndex: (state, { payload }) => {
            state[`step${payload.index}`] = payload.data;
        }
    }
});

export const { resetState, setStepIndex } = form.actions;

export default form.reducer;
