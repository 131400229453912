import 'antd/dist/antd.less';
import '../src/styles/globals.scss';

import { Provider } from 'react-redux';

import MainContainer from '../src/containers/MainContainer';

import { store } from '../src/redux';

// Workaround to hide an error from the console cuz antd sucks and they haven't fixed it yet.

// eslint-disable-next-line
const consoleError = console.error.bind(console);

// eslint-disable-next-line
console.error = (errObj, ...args) => {
    if (typeof errObj === 'string' && errObj.endsWith('https://reactjs.org/link/strict-mode-find-node%s')) {
        return;
    }
    consoleError(errObj, ...args);
};

function MyApp({ Component, pageProps }) {
    return (
        <Provider store={store}>
            <MainContainer>
                <Component {...pageProps} />
            </MainContainer>
        </Provider>
    );
}

export default MyApp;
