import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    params: {
        APIKey: process.env.NEXT_PUBLIC_API_KEY
    }
});

export const MULTIPART_FORM_DATA = {
    headers: {
        'content-type': 'multipart/form-data'
    }
};

export const errorHandler = (e, rejectWithValue) => {
    const rejectObj = {
        data: e?.response?.data || {},
        status: e?.response?.status || 999
    };
    return rejectWithValue(rejectObj);
};

export default axiosInstance;
