import { Button } from 'antd';
import classnames from 'classnames';
import { useEffect, useState } from 'react';

import { handleHeight, setParentHeight, handleAppBehaviourOnLoad } from '../../utils/helpers';

import styles from './MainContainer.module.scss';

const MainContainer = ({ children }) => {
    const [isIframe, setIsIframe] = useState(true);

    useEffect(() => {
        // setParentHeight();

        // setIsIframe(window !== window.parent);

        // Temporary fix.
        // handleAppBehaviourOnLoad(setIsIframe);

        handleHeight();
    }, []);

    if (!isIframe) {
        return (
            <div id='app_wrapper' className={classnames(styles.wrapper, styles.noIframeWrapper)}>
                <div>
                    <h1>Oops, nu ai ajuns unde trebuie.</h1>
                    <Button href={process.env.NEXT_PUBLIC_APP_URL} type='primary' size='large'>
                        Hai pe dacia.ro
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div id='app_wrapper' className={styles.wrapper}>
            {children}
        </div>
    );
};

export default MainContainer;
