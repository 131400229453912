import { getFileLimitByType } from './helpers';

const APP_CONFIG = {
    formErrorMessages: {
        required: '* Câmp obligatoriu',
        date: '* Data nu este corectă. Aceasta trebuie să fie scrisă sub forma AAAA-LL-ZZ',
        ctel: '* Numărul de telefon este scris eronat',
        email: '* Adresa de e-mail este scrisă greșit',
        CNP: '* Cod numeric personal invalid',
        requiredStep2: '* Selectează minim 1 opțiuni',
        requiredStep4: '* Selectează o opțiune',
        requiredStep5: '* Opțiune obligatorie'
    },
    counties: [
        'Alba',
        'Arad',
        'Arges',
        'Bacau',
        'Bihor',
        'Bistrita Nasaud',
        'Botosani',
        'Braila',
        'Brasov',
        'Bucuresti',
        'Buzau',
        'Calarasi',
        'Caras Severin',
        'Cluj',
        'Constanta',
        'Covasna',
        'Dambovita',
        'Dolj',
        'Galati',
        'Giurgiu',
        'Gorj',
        'Harghita',
        'Hunedoara',
        'Ialomita',
        'Iasi',
        'Ilfov',
        'Maramures',
        'Mehedinti',
        'Mures',
        'Neamt',
        'Olt',
        'Prahova',
        'Salaj',
        'Satu Mare',
        'Sibiu',
        'Suceava',
        'Teleorman',
        'Timis',
        'Tulcea',
        'Valcea',
        'Vaslui',
        'Vrancea'
    ],
    formSteps: [
        {
            title: 'Pasul 1',
            description: 'Introducerea datelor personale'
        },
        {
            title: 'Pasul 2',
            description: 'Selectarea adeverințelor solicitate'
        },
        {
            title: 'Pasul 3',
            description: 'Introducerea datelor personale'
        },
        {
            title: 'Pasul 4',
            description: 'Selectarea adeverințelor solicitate'
        },
        {
            title: 'Pasul 5',
            description: 'Selectarea adeverințelor solicitate'
        }
    ],
    step5FileTypes: {
        CI: 'CI',
        CM: 'CM',
        limitCI: 2, // file max mb
        limitCM: 10, // file max mb
        allowedMimeTypes: [
            '.rar',
            '.zip',
            '.jpg',
            '.jpeg',
            '.jpe',
            '.png',
            '.pdf',
            '.PDF'
            // 'image/png',
            // 'image/jpg',
            // 'image/jpe',
            // 'image/jpeg',
            // 'application/pdf',
            // 'application/zip',
            // 'application/x-rar',
            // // fallback rar...
            // 'application/vnd.rar',
            // 'application/octet-stream',
            // 'application/x-rar-compressed',
            // 'application/rar'
        ]
    },
    REGEX: {
        date: /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/,
        email: /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/i,
        CNP: /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/,
        ctel: /^0[0-9]{9}$/
    }
};

export const FORM_RULES = {
    required: {
        required: true,
        message: APP_CONFIG.formErrorMessages.required
    },
    requiredStep2: {
        required: true,
        message: APP_CONFIG.formErrorMessages.requiredStep2
    },
    requiredStep4: {
        required: true,
        message: APP_CONFIG.formErrorMessages.requiredStep4
    },
    requiredStep5: {
        required: true,
        message: APP_CONFIG.formErrorMessages.requiredStep5
    },
    email: {
        type: 'email',
        pattern: APP_CONFIG.REGEX.email,
        message: APP_CONFIG.formErrorMessages.email
    },
    date: () => ({
        validator(_, value) {
            if (APP_CONFIG.REGEX.date.test(value)) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(APP_CONFIG.formErrorMessages.date));
        }
    }),
    CNP: {
        type: 'string',
        pattern: APP_CONFIG.REGEX.CNP,
        message: APP_CONFIG.formErrorMessages.CNP
    },
    ctel: {
        type: 'string',
        pattern: APP_CONFIG.REGEX.ctel,
        message: APP_CONFIG.formErrorMessages.ctel
    },
    // type = CI | CM; errorType = 'size' | 'type'
    file: (errorState, type, errorType) => {
        return () => ({
            validator(_, value) {
                if (!errorState?.[type]?.[errorType]) {
                    return Promise.resolve();
                }

                const message =
                    errorType === 'size'
                        ? `Documentul trebuie să aibă maxim ${getFileLimitByType(type)} MB.`
                        : `${errorState[type].filename} nu este un fișier valid.`;

                return Promise.reject(new Error(message));
            }
        });
    }
};

export const MOCKS = {
    step1: {
        cname: '',
        cname_father: '',
        cname_mother: '',
        ctel: '',
        email: '',
        angajat: '',
        bday: '',
        bplace: '',
        bplace_city: '',
        judet: '',
        domiciliu_city: '',
        cstreet: '',
        nrstreet: '',
        bloc: '',
        scara: '',
        ap: '',
        cnp: ''
    }
};

export default APP_CONFIG;
