import APP_CONFIG from './config';

export const validateCNP = (p_cnp) => {
    var i = 0,
        year = 0,
        hashResult = 0,
        cnp = [],
        hashTable = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    if (p_cnp?.length !== 13) {
        return false;
    }
    for (i = 0; i < 13; i++) {
        cnp[i] = parseInt(p_cnp.charAt(i), 10);
        if (isNaN(cnp[i])) {
            return false;
        }
        if (i < 12) {
            hashResult = hashResult + cnp[i] * hashTable[i];
        }
    }
    hashResult = hashResult % 11;
    if (hashResult === 10) {
        hashResult = 1;
    }
    year = cnp[1] * 10 + cnp[2];
    switch (cnp[0]) {
        case 1:
        case 2:
            {
                year += 1900;
            }
            break;
        case 3:
        case 4:
            {
                year += 1800;
            }
            break;
        case 5:
        case 6:
            {
                year += 2000;
            }
            break;
        case 7:
        case 8:
        case 9:
            {
                year += 2000;
                if (year > parseInt(new Date().getYear(), 10) - 14) {
                    year -= 100;
                }
            }
            break;
        default: {
            return false;
        }
    }
    if (year < 1800 || year > 2099) {
        return false;
    }
    return cnp[12] === hashResult;
};

export const getFileLimitByType = (type) => {
    return type === APP_CONFIG.step5FileTypes.CI
        ? APP_CONFIG.step5FileTypes.limitCI
        : APP_CONFIG.step5FileTypes.limitCM;
};

export const transformData = (values) => {
    const final = {
        ...values.step1,
        tip_adeverinta: JSON.stringify(
            values.step2.tip_adeverinta.reduce((acc, item, index) => ({ ...acc, [index]: item }), {})
        ),
        ...values.step3,
        ...values.step4,
        agree1: values.step5.agree1,
        agree2: values.step5.agree2
    };

    console.log('formatted values (except files):', final);

    const formData = new FormData();

    console.log(values.step5?.file1);

    if (values.step5?.file1?.length) {
        formData.append('file1', values.step5.file1[0].originFileObj);
    }

    if (values.step5?.file2?.length) {
        formData.append('file2', values.step5.file2[0].originFileObj);
    }

    Object.keys(final).forEach((key) => {
        formData.append(key, final[key]);
    });

    return formData;
};

// alternative approach in case the resize observer is not working properly.
export const setParentHeight = () => {
    setTimeout(() => {
        const element = document.getElementById('app_wrapper');
        console.log('element.clientHeight', element.clientHeight);

        window.parent.postMessage(
            { type: 'IFRAME_HEIGHT_UPDATE', value: element.clientHeight + 50 }, // 50 = buffer
            process.env.NEXT_PUBLIC_PARENT_ORIGIN
        );
    }, 500);

    // for testing purposes on https://maniacal-spark.surge.sh/
    if (process.env.NEXT_PUBLIC_PARENT_ORIGIN_ALTERNATIVE) {
        setTimeout(() => {
            const element = document.getElementById('app_wrapper');
            console.log('element.clientHeight', element.clientHeight);

            window.parent.postMessage(
                { type: 'IFRAME_HEIGHT_UPDATE', value: element.clientHeight },
                process.env.NEXT_PUBLIC_PARENT_ORIGIN_ALTERNATIVE
            );
        }, 500);
    }
};

export const scrollParentBy = (value) => {
    setTimeout(() => {
        window.parent.postMessage({ type: 'SCROLL_BY_VALUE', value }, process.env.NEXT_PUBLIC_PARENT_ORIGIN);
    }, 200);

    if (process.env.NEXT_PUBLIC_PARENT_ORIGIN_ALTERNATIVE) {
        setTimeout(() => {
            window.parent.postMessage(
                { type: 'SCROLL_BY_VALUE', value },
                process.env.NEXT_PUBLIC_PARENT_ORIGIN_ALTERNATIVE
            );
        }, 200);
    }
};

export const handleHeight = () => {
    const root = document.getElementById('__next');

    try {
        const resizeObserver = new ResizeObserver((entries) => {
            const entry = entries[0];
            if (entry) {
                try {
                    window.parent.postMessage(
                        { type: 'IFRAME_HEIGHT_UPDATE', value: entry.contentRect.height },
                        process.env.NEXT_PUBLIC_PARENT_ORIGIN
                    );
                } catch (e) {
                    console.log('postMessage IFRAME_HEIGHT_UPDATE', e);
                }

                if (process.env.NEXT_PUBLIC_PARENT_ORIGIN_ALTERNATIVE) {
                    window.parent.postMessage(
                        { type: 'IFRAME_HEIGHT_UPDATE', value: entry.contentRect.height },
                        process.env.NEXT_PUBLIC_PARENT_ORIGIN_ALTERNATIVE
                    );
                }
            }
        });

        resizeObserver.observe(root);
    } catch (e) {
        console.log('app.js resize', e);
    }
};

export const handleAppBehaviourOnLoad = (setIsIframe) => {
    const allowedParentOrigins = [
        process.env.NEXT_PUBLIC_PARENT_ORIGIN,
        process.env.NEXT_PUBLIC_PARENT_ORIGIN_ALTERNATIVE
    ].filter((x) => x);

    allowedParentOrigins.forEach((value) => {
        window.addEventListener(
            'message',
            (event) => {
                if (!allowedParentOrigins.includes(event.origin)) {
                    return;
                }

                try {
                    const data = JSON.parse(event.data);

                    if (data?.type === 'PARENT_ORIGIN') {
                        setIsIframe(true);
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            value
        );
    });
};
